<template>
  <div>
    <div class="news">
      <div class="title">
        GV20 Therapeutics Appoints Christopher K. Mirabelli, Ph.D. to Board of Directors
      </div>
      <div class="time">
        Jan 9, 2024
      </div>
      <div class="content">
        
        <p>
          Cambridge, MA – January 9, 2024 - GV20 Therapeutics, a clinical-stage biotechnology company integrating AI, genomics, and cancer biology to create revolutionary antibody therapeutics, today announced the appointment of Christopher K. Mirabelli, Ph.D. to its Board of Directors as an independent member.
        </p>
        <p>
          With over 40 years in pharma and biotech, Dr. Mirabelli brings extensive experience across drug R&D, executive leadership, and venture capital roles. He currently serves as Chairman of Leap Therapeutics, which he co-founded and led as CEO until 2020. As Managing Director at HealthCare Ventures since 2000, Dr. Mirabelli has been involved in over 15 investments in therapeutics companies. In 1993, Dr. Mirabelli joined LeukoSite as the founding CEO, growing its oncology and inflammation/autoimmune drug development pipeline and culminating in the acquisition of the company by Millennium in 1999.  He has been directly involved in the development of multiple approved drugs including Campath/Lemtrada, Velcade, Entyvio, and Tafamidis/Vyndamax.
        </p>
        <p>
          Earlier in his career, Dr. Mirabelli held senior research positions at SmithKline & French Laboratories and co-founded Ionis Pharmaceuticals where he served as Senior Vice President of Research.
        </p>
        <p>
          "We are absolutely thrilled to welcome Chris to our Board with his unparalleled industry experience and record of building successful biotech companies," said Dr. Shirley Liu, CEO of GV20. "His involvement on our Board will prove invaluable as we advance our pipeline and pursue financing opportunities."
        </p>
        <p>
          "GV20's integrated approach harnessing AI, genomics, and cancer biology represents an exciting new frontier for target identification and antibody discovery," commented Dr. Mirabelli. "Their powerful technology platform has remarkable potential to unlock novel targets and therapies in oncology. I look forward to working closely with Shirley and the leadership team to help translate this innovative technology into new treatments that improve cancer patient outcomes."
        </p>
        <p>
          <div class="font-weight-bold">
            About GV20 Therapeutics
          </div>
          GV20 Therapeutics is a biotechnology company with sites in Cambridge, Massachusetts and Shanghai, China. GV20 integrate artificial intelligence, high-throughput functional genomics, and cancer biology to identify novel cancer drug targets and antibody therapeutics. “GV20” references one of the most important acupoints for the human body, symbolizing the company’s mission to find and exploit vulnerabilities in cancer biology.
        </p>
        <p>
          To learn more about GV20, please visit <a style="color:#2d8cf0;" href="https://mobile.gv20tx.com/">https://gv20tx.com/</a> and follow the company on <a style="color:#2d8cf0;" href="https://www.linkedin.com/company/gv20tx/">LinkedIn</a>.
        </p>

        <p>
          <div class="font-weight-bold">
            Business Contact
          </div>
          Ying Gong, Ph.D., CBO<br>
          BD@gv20tx.com
        </p>

      </div>
    </div>
    <Footers />
  </div>
</template>
<script>
import Footers from '@/components/Footers'
export default {
  data () {
    return {

    }
  },
  components: {
    Footers
  }
}
</script>
<style scoped lang="scss">
.news {
  font-size: 30px;
  font-weight: 500;
  padding: 0 40px;
  .title {
    font-weight: bold;
    font-size: 40px;
    padding-top: 40px;
  }
  .time {
    font-size: 20px;
    color: #666666;
    font-style: italic;
    margin: 50px 0;
  }
  .item {
    .font-weight-bold {
      margin-bottom: 30px;
    }
  }
}
</style>
